.spinner {
    &.fixed {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
    }
}

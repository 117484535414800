.app {
    box-sizing: border-box;
    display: flex;
    overflow: auto;
    height: 100%;
    justify-content: center;
    padding: 16px;
    position: absolute;
    width: 100%;
}

/* Theme variables from Rosé Pine Moon
 * https://github.com/rose-pine/rose-pine-theme/blob/main/palette.md
 */

// Accents
$love: #b4637a;
$gold: #ea9d34;
$rose: #d7827e;
$pine: #286983;
$foam: #56949f;
$iris: #907aa9;

// Neutral
$text: #575279;
$subtle: #797593;
$ignored: #9893a5;
$overlay: #f2e9e1;
$surface: #fffaf3;
$base: #faf4ed;

// Breakpoints
$maxMobileWidthBreakpoint: 479px;
$maxTabletWidthBreakpoint: 767px;

// Highlights
$overlay-highlight: #f4ede8;
$highlight: #dfdad9;
$inactive-highlight: #cecacd;

// Mixins
@mixin interactable {
    background-color: $surface;
    border: 2px solid $overlay;
    border-radius: 8px;
    box-sizing: border-box;
    color: $text;
    display: block;
    font-size: 16px;
    margin: 8px 0;
    padding: 8px;
    outline: none;
}

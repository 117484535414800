@import 'settings';
@import 'mui';

html,
body {
    background-color: $base;
    color: $text;
    font-size: 16px;
    margin: 0;
    overflow-wrap: break-word;
}

* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

a {
    color: $rose;

    &:hover,
    &:visited {
        color: $love;
    }
}

code {
    color: $iris;
    white-space: pre-wrap;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        flex: 1;
    }

    input,
    label > input {
        @include interactable;
        align-self: center;
        width: 100%;
    }

    textarea,
    label > textarea {
        resize: none;
        width: 100%;
    }

    .input-collection {
        display: flex;
        flex-direction: row;
        flex: 1;

        label {
            width: 100%;
            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }

        @media (max-width: $maxMobileWidthBreakpoint) {
            flex-direction: column;
            label:not(:last-of-type) {
                margin-right: 0;
            }
        }
    }
}

hr {
    border: 1px solid $overlay;
    margin: 32px 0;
}

h1,
h2,
h3,
h4 {
    margin: 16px 0 8px 0;
}
